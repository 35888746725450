
import axios from "@/axios"

export async function getInvoiceList() {
    const url = '/invoice'
    return axios.get(url)
}
export async function getInvoiceOrderSummary(OrderId) {
    const url = `/invoiceorder-summary/${OrderId}`
    return axios.get(url)
}
export async function getInvoiceHistory(OrderId) {
    const url = `/invoicepayment-history/${OrderId}`
    return axios.get(url)
}
export async function getInvoiceRecord(OrderId, payload) {
    const url = `/invoice/${OrderId}`
    return axios.post(url, payload)
}
export async function getInvoiceDetails(OrderId) {
    const url = `/invoice/${OrderId}`
    return axios.get(url)
}
export async function getTenantAddress(tanentId) {
    const url = `/tenant/address/${tanentId}`
    return axios.get(url)
}